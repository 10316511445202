export default function urlCalc(){

	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});

	let machinery = params.machinery;
	let operates = params.operates;
	let bill = params.bill;

	if(bill){
		// convert currency to number
		bill = Number(bill.replace(/[^0-9.-]+/g,""));
	}else{
		bill = 0;
	}

	if(!operates){ operates = 'Warehouse'; }
	if(!machinery){ machinery = 'Yes'; }

	switch(operates){
		case 'Hotel Or Motel':
			operates = 'Hotel/Motel';
			break;
	}

	if(machinery.toLowerCase() === "yes"){
		bill = bill * .15;
	}else{
		bill = bill * .3;
	}

	// format bill $$
	bill = "$" + Math.floor(bill).toLocaleString();

	let billEls = document.querySelectorAll('[data-bill]');
	let operateEls = document.querySelectorAll('[data-operates]');

	billEls.forEach(function(el, index){
		el.textContent = bill;
	});

	operateEls.forEach(function(el, index){
		el.textContent = operates;
	});
}