export default function lazyScript() {
	var hasLoaded = false;

	window.scrollTo(0,0);

	if(window.scrollY >= 1000){ 
		hasLoaded = true;
		loadScripts();
	}

	window.addEventListener('scroll', function(){
		if(window.scrollY >= 1000 && !hasLoaded){ 
			hasLoaded = true; 
			loadScripts(); 
		}
	});

	function loadScripts(){
		var lazyScripts = document.querySelectorAll('script[data-src]');

		if(lazyScripts.length){
			lazyScripts.forEach(function(el, index){
				el.setAttribute('src', el.dataset.src);
			});
		}
	}
}