export default function getCurrentMonth() {
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now       = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var output = document.getElementsByClassName('output');

    $( ".output" ).html( thisMonth);

    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }

    $( ".output-full").html(now.getDate()+nth(now.getDate())+' '+thisMonth+' '+now.getFullYear());
}
